import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "./pages/Layout/Layout";
import { Clicker } from "./pages/Clicker/Clicker";
import { NoPage } from "./pages/NoPage/NoPage";
import { Tasks } from "./pages/Tasks/Tasks";
import { WebSocketProvider } from "./contexts/WebsocketContext";
import { getConfig } from "./utils/config";
import { getTelegramUser } from "./services/telegramService";
import Loader from "./components/Loader/Loader";
import { UserProvider } from "./contexts/UserContext";
import MaintenanceNotice from "./pages/MaintenanceNotice/MaintenanceNotice";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { useEffect, useRef } from "react";
import { Boosts } from "./pages/Boosts/Boosts";
import { LoadingProvider } from "./contexts/LoadingContext";
import {
  AdminSettings,
  AdminTasks,
  AdminUsers,
  AdminRoute,
  AddTask,
} from "./pages/Admin";
import GlobalStyle from "./globalStyles";

import { Friend } from "./pages/Friend/Friend";
import { Mine } from "./pages/Mine/Mine";
import { Airdrop } from "./pages/Airdrop/Airdrop";
import { Settings } from "./pages/Setting";
import { Process } from "./pages/Process";
import { AdminProducts } from "./pages/Admin/AdminProducts/AdminProducts";
import { AddProduct } from "./pages/Admin/AdminProducts/AddProduct";
import { AdminCategories } from "./pages/Admin/AdminCategories/AdminCategories";
import { AddCategories } from "./pages/Admin/AdminCategories/AddCategories";
import { ToastContainer } from "react-toastify";
import { AdminDiamond } from "./pages/Admin/AdminDiamond/AdminDiamond";
import { AddDiamond } from "./pages/Admin/AdminDiamond/AddDiamond";
import { AdminRanks } from "./pages/Admin/AdminRanks/AdminRanks";
import { AddRank } from "./pages/Admin/AdminRanks/AddRank";
import { AdminDaily } from "./pages/Admin/AdminDaily/AdminDaily";
import { AddDaily } from "./pages/Admin/AdminDaily/AddDaily";
import { AdminBundle } from "./pages/Admin/AdminBundle/AdminBundle";
import { AddBundle } from "./pages/Admin/AdminBundle/AddBundle";
import { AdminBoost } from "./pages/Admin/AdminBoost/AdminBoost";
import { AddBoost } from "./pages/Admin/AdminBoost/AddBoost";

function switchSwipeDown(enable: boolean) {
  window.Telegram.WebApp.expand();
  // document.body.style.overflow = enable ? "hidden" : "auto";
  // // document.body.style.marginTop = enable ? "100px" : "0";
  // document.body.style.height = enable
  //   ? `${window.innerHeight + 100}px`
  //   : "100vh";
  // window.scrollTo(0, enable ? +100 : 0);
  // window.addEventListener("scroll", (e) => {
  //   e.preventDefault();
  // });

  // document.addEventListener("gesturestart", function (e) {
  //   e.preventDefault();
  // });
}

function App() {
  const { serverUrl, adminPassword } = getConfig();
  const telegramUser = getTelegramUser();

  useEffect(() => {
    switchSwipeDown(true);
  }, []);

  return (
    <BrowserRouter>
      <TonConnectUIProvider manifestUrl="https://taptap.videonft.live/tonconnect-manifest.json">
        <LoadingProvider>
          <WebSocketProvider url={serverUrl} user_id={telegramUser?.id}>
            <UserProvider user_id={telegramUser.id}>
              <GlobalStyle />
              <ToastContainer />
              <Routes>
                <Route path="process" element={<Process />} />
                <Route path="setting" element={<Settings />} />
                <Route path="boost" element={<Boosts />} />
                <Route path="/" loader={() => <Loader />} element={<Layout />}>
                  <Route index element={<Clicker />} />

                  <Route path="friend" element={<Friend />} />
                  <Route path="mine" element={<Mine />} />
                  <Route path="tasks" element={<Tasks />} />
                  <Route path="airdrop" element={<Airdrop />} />

                  <Route
                    path="maintenance-notice"
                    element={<MaintenanceNotice />}
                  />
                </Route>
                <Route
                  path="/admin"
                  element={<AdminRoute password={adminPassword} />}
                >
                  <Route index element={<AdminSettings />} />
                  <Route path="tasks" element={<AdminTasks />} />
                  <Route path="tasks/add" element={<AddTask />} />
                  <Route path="users" element={<AdminUsers />} />
                  <Route path="products" element={<AdminProducts />} />
                  <Route path="products/:id" element={<AddProduct />} />
                  <Route path="products/add" element={<AddProduct />} />

                  <Route path="diamond" element={<AdminDiamond />} />
                  <Route path="diamond/:id" element={<AddDiamond />} />
                  <Route path="diamond/add" element={<AddDiamond />} />

                  <Route path="categories" element={<AdminCategories />} />
                  <Route path="categories/add" element={<AddCategories />} />
                  <Route path="settings" element={<AdminSettings />} />

                  <Route path="ranks" element={<AdminRanks />} />
                  <Route path="ranks/:id" element={<AddRank />} />
                  <Route path="ranks/add" element={<AddRank />} />

                  <Route path="bundles" element={<AdminBundle />} />
                  <Route path="bundles/:id" element={<AddBundle />} />
                  <Route path="bundles/add" element={<AddBundle />} />

                  <Route path="daily" element={<AdminDaily />} />
                  <Route path="daily/:id" element={<AddDaily />} />
                  <Route path="daily/add" element={<AddDaily />} />

                  <Route path="boost" element={<AdminBoost />} />
                  <Route path="boost/:type" element={<AddBoost />} />
                </Route>
                <Route path="*" element={<NoPage />} />
              </Routes>
            </UserProvider>
          </WebSocketProvider>
        </LoadingProvider>
      </TonConnectUIProvider>
    </BrowserRouter>
  );
}

export default App;
