import styled from "styled-components";
import { formatMoney, formatMoneyDecimal } from "../utils/common";
import { Styled as SheetStyled } from "./SheetAddDiamond";
import { useWebSocket } from "../hooks/useWebsocket";
import { useEffect, useState } from "react";
import { useUser } from "../hooks/useUser";
import { toast } from "react-toastify";
import { User } from "../common/models";

interface IProps {
  isShow: boolean;
  onClose: Function;
}

export const SheetRanking = ({ isShow, onClose }: IProps) => {
  const [topUsers, setTopUsers] = useState<User[]>([]);
  const { webSocket } = useWebSocket();
  useEffect(() => {
    if (webSocket && isShow) {
      // console.log("getTopUsers");
      webSocket.emit("getTopUsers");
      webSocket.on("topUsers", (data: any) => {
        // console.log(data);
        setTopUsers(data);
      });
    }
    return () => {
      if (webSocket) {
        webSocket.off("topUsers");
      }
    };
  }, [isShow]);

  const { user: currentUser } = useUser();

  let currentName = currentUser?.firstName + " " + currentUser?.lastName;
  if (!currentUser?.firstName && !currentUser?.lastName) {
    currentName = currentUser?.tgUsername || "";
  }
  return (
    <>
      {isShow && <SheetStyled.Blur onClick={() => onClose()} />}
      <SheetStyled.Sheet className={isShow ? "show" : ""}>
        <SheetStyled.Close onClick={() => onClose()}>
          <img src="/img/close.svg" />
        </SheetStyled.Close>
        <SheetStyled.Content>
          <Styled.Title>Leaderboard</Styled.Title>
          <Styled.List>
            {topUsers.map((user, idx) => {
              let name = [user.firstName, user.lastName]
                .filter((a) => a)
                .join(" ");
              if (!user.firstName && !user.lastName) {
                name = user.tgUsername;
              }
              return (
                <Styled.Item
                  key={user.tgId + "" + idx}
                  className={`top${idx + 1} ${
                    currentUser?.tgId == user.tgId ? "me" : ""
                  }`}
                >
                  <Styled.ItemIndex>{idx + 1}</Styled.ItemIndex>
                  <Styled.ItemAvatar>
                    <img src={user.avatarUrl || "/img/logo.png"} />
                  </Styled.ItemAvatar>
                  <Styled.ItemContent>
                    <Styled.ItemName>
                      {name} {user.tgId == currentUser?.tgId ? "( You )" : ""}
                    </Styled.ItemName>
                    <Styled.ItemBalance>
                      <img src="/img/coin.svg" />
                      {formatMoney(user.totalBalance)}
                    </Styled.ItemBalance>
                  </Styled.ItemContent>
                  {/* {user.topProfit && (
                    <Styled.ItemProfit>
                      <img src="/img/dinamod.svg" />
                      {formatMoneyDecimal(user.topProfit)}
                    </Styled.ItemProfit>
                  )} */}
                </Styled.Item>
              );
            })}
          </Styled.List>
          <Styled.You>
            <Styled.YouContent>
              <Styled.YouName>{currentName}</Styled.YouName>
              <Styled.YouBalance>
                <img src="/img/coin.svg" />
                {formatMoney(currentUser?.totalBalance)}
              </Styled.YouBalance>
            </Styled.YouContent>
            <Styled.YouRanking>{currentUser?.ranking}</Styled.YouRanking>
            <Styled.YouAvatar>
              <img src={currentUser?.avatarUrl || "/img/coin.svg"} />
            </Styled.YouAvatar>
          </Styled.You>
        </SheetStyled.Content>
      </SheetStyled.Sheet>
    </>
  );
};

const Styled = {
  You: styled.div`
    background: linear-gradient(100.78deg, #6161fb 0%, #b15aff 100%);
    padding: 0 16px;
    display: flex;
    align-items: center;

    color: #ffffff;
    text-align: left;
    border-radius: 32px;
    gap: 12px;
    height: 58px;

    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
  `,
  YouContent: styled.div`
    flex: 1;
  `,
  YouName: styled.div`
    font-size: 18px;
  `,
  YouBalance: styled.div`
    display: flex;
    font-family: Quantico;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.02px;
    text-align: center;

    gap: 4px;
  `,
  YouRanking: styled.div`
    font-family: Quantico;
    font-size: 12px;
    font-weight: 700;
    line-height: 17.16px;
  `,
  YouAvatar: styled.div`
    height: 32px;
    width: 32px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
  Title: styled.div`
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0.5px solid #777777;
    span {
      font-family: Quantico;
      color: #76f0fd;
      font-weight: 700;
    }
    gap: 4px;
    margin-bottom: 8px;
  `,
  List: styled.div`
    max-height: 70vh;
    overflow-y: auto;
    padding-bottom: 60px;
  `,
  Item: styled.div`
    background: #282b30;
    color: #fff;
    border-radius: 16px;
    display: flex;
    text-align: left;
    margin-bottom: 8px;

    gap: 12px;
    padding: 0 8px;

    height: 64px;

    align-items: center;

    &.top1 {
      background: #ffde50;
      color: #1a1a1a;
    }
    &.top2 {
      background: #b5b5b5;
      color: #1a1a1a;
    }
    &.top3 {
      background: #bf7d4d;
      color: #1a1a1a;
    }
    &.me {
      background: linear-gradient(100.78deg, #6161fb 0%, #b15aff 100%);
      color: #ffffff;
    }
  `,
  ItemIndex: styled.div`
    width: 32px;
    text-align: center;
    font-family: Quantico;
    font-size: 24px;
    font-weight: 700;
    line-height: 34.32px;
    text-align: center;
  `,
  ItemAvatar: styled.div`
    height: 48px;
    width: 48px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
  ItemContent: styled.div`
    flex: 1;
  `,
  ItemName: styled.div`
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  `,
  ItemBalance: styled.div`
    display: flex;
    font-family: Quantico;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.02px;
    text-align: center;

    gap: 4px;
  `,
  ItemProfit: styled.div`
    width: 75px;
    height: 28px;
    border-radius: 8px;
    background: #00000014;

    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;

    font-family: Quantico;
    font-size: 14px;
    font-weight: 700;
    line-height: 20.02px;
    text-shadow: 0px 1px 0px 0px #00000040;
    color: #fff;
  `,
};
