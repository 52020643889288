import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../hooks/useUser";
import styled from "styled-components";
import { formatMoney } from "../utils/common";
import { SheetInfo } from "./SheetInfo";
import { useState } from "react";
import { SheetBuyDiamond } from "./SheetBuyDiamond";

export const Header = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  const rank = user?.rank;

  const [isInfoCoin, setIsInfoCoin] = useState(false);
  const [isInfoDiamond, setIsInfoDiamond] = useState(false);
  const [isBuyDiamond, setIsBuyDiamond] = useState(false);
  // console.log("user", user);
  return (
    <>
      <HeaderStyled.Wrap>
        <HeaderStyled.Top>
          <HeaderStyled.Avatar>
            <img src={rank?.key || "/img/logo.png"} alt="avatar" />
          </HeaderStyled.Avatar>
          <HeaderStyled.Rank>
            <HeaderStyled.Info>
              <p>{user?.firstName || user?.tgUsername || ""}</p>

              <HeaderStyled.RankName to="/process">
                {rank?.name || ""}
                <img src="/img/next.svg" alt="next" />
              </HeaderStyled.RankName>
            </HeaderStyled.Info>

            <HeaderStyled.Process>
              <HeaderStyled.Line>
                <HeaderStyled.LineInner
                  style={{
                    width:
                      Math.min(
                        (((user?.totalBalance || 0) - rank?.min) / rank?.max) *
                          100,
                        100
                      ) + "%",
                  }}
                />
              </HeaderStyled.Line>

              <HeaderStyled.Count>
                <strong>{rank?.level}</strong>
                <span>/{user?.totalRank}</span>
              </HeaderStyled.Count>
            </HeaderStyled.Process>
          </HeaderStyled.Rank>
          <HeaderStyled.Setting onClick={() => navigate("/setting")}>
            <img src="/img/setting.svg" />
          </HeaderStyled.Setting>
        </HeaderStyled.Top>

        <HeaderStyled.Menus>
          <HeaderStyled.MenuLeft>
            <HeaderStyled.MenuIcon>
              <img src="/img/coin.svg" alt="coin" />
            </HeaderStyled.MenuIcon>
            <HeaderStyled.MenuCoin>
              <HeaderStyled.MenuLabel onClick={() => setIsInfoCoin(true)}>
                Profit per hour
                <img src="/img/info.svg" alt="coin" />
              </HeaderStyled.MenuLabel>
              <strong>{formatMoney(user?.profitPerHour)}</strong>
            </HeaderStyled.MenuCoin>
          </HeaderStyled.MenuLeft>

          <HeaderStyled.MenuRight>
            <HeaderStyled.MenuIcon>
              <img
                src="/img/chest.svg"
                alt="coin"
                style={{
                  transform: "scale(1.5)",
                }}
              />
            </HeaderStyled.MenuIcon>
            <HeaderStyled.MenuCoin>
              <HeaderStyled.MenuLabel onClick={() => setIsInfoDiamond(true)}>
                $SPIN
                <img src="/img/info.svg" alt="coin" />
              </HeaderStyled.MenuLabel>
              <strong>{formatMoney(user?.diamondBalance)}</strong>
            </HeaderStyled.MenuCoin>
            <img
              src="/img/buy.svg"
              alt="next"
              onClick={() => setIsBuyDiamond(true)}
            />
          </HeaderStyled.MenuRight>
        </HeaderStyled.Menus>
      </HeaderStyled.Wrap>

      <SheetBuyDiamond
        isShow={isBuyDiamond}
        onClose={() => {
          setIsBuyDiamond(false);
        }}
        onDone={() => {
          setIsBuyDiamond(false);
        }}
      />

      <SheetInfo
        item={
          isInfoCoin
            ? {
                icon: "/img/coin.svg",
                name: (
                  <>
                    Tap<span style={{ color: "#77CCE7" }}>Anime</span> Coin
                  </>
                ),
                description:
                  "This is the amount of money you earn every hour. The more you earn, the faster you can upgrade your rank.",
              }
            : null
        }
        onClose={() => setIsInfoCoin(false)}
      />
      <SheetInfo
        item={
          isInfoDiamond
            ? {
                icon: "/img/dinamod.svg",
                name: "$SPIN",
                description:
                  "This is the amount of money you earn every hour. The more you earn, the faster you can upgrade your rank.",
              }
            : null
        }
        onClose={() => setIsInfoDiamond(false)}
      />
    </>
  );
};

const HeaderStyled = {
  Wrap: styled.div`
    z-index: 100;
    width: 100%;
    padding: 16px;
  `,
  Top: styled.div`
    height: 36px;
    display: flex;
    align-items: center;
    gap: 8px;

    margin-bottom: 16px;
  `,
  Info: styled.div`
    display: flex;
    max-width: 170px;
    p {
      color: white;
      font-size: 14px;
      line-height: 14px;
      margin: 0;
      flex: 1;
    }
    small {
      color: #8e8e8e;
      line-height: 10px;
      margin: 0;
      font-size: 10px;
    }
  `,
  Setting: styled.div``,
  Avatar: styled.div`
    height: 36px;
    width: 36px;
    border-radius: 6px;
    background-color: #1e1e1e;
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  Process: styled.div`
    max-width: 205px;
    display: flex;
    gap: 12px;
    align-items: center;
  `,
  Line: styled.div`
    height: 8px;
    flex: 1;

    background-color: #1e1e1e;
    border-radius: 4px;
    border: 0.2px solid #787878;
  `,
  LineInner: styled.div`
    background: linear-gradient(
      90deg,
      #98da92 0%,
      #cba2ce 30.2%,
      #c584dd 65.91%,
      #826ee1 100%
    );

    width: 80%;
    height: 100%;
    border-radius: 4px;
  `,

  Count: styled.div`
    font-size: 12px;
    strong {
      color: white;
    }
    span {
      color: #787878;
    }
  `,
  Rank: styled.div`
    flex: 1;
    // display: flex;
    // margin-bottom: 8px;
  `,
  RankName: styled(Link)`
    display: flex;
    font-size: 12px;
    color: white;
    gap: 4px;
  `,

  Menus: styled.div`
    border: 0.5px solid #cae77680;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 30px;

    background-color: #101010;

    gap: 4px;
    padding: 0 4px;
  `,
  MenuLeft: styled.div`
    display: flex;
    height: 40px;
    flex: 1;
    align-items: center;
    border-radius: 30px 10px 10px 30px;
    background-image: linear-gradient(90deg, #481b50 0%, #251b81 100%);
    padding: 0 4px;
    gap: 4px;
  `,
  MenuRight: styled.div`
    display: flex;
    height: 40px;
    flex: 1;
    align-items: center;
    border-radius: 10px 30px 30px 10px;
    background-image: linear-gradient(90deg, #251b81 0%, #481b50 100%);
    padding: 0 4px;
    padding-right: 8px;
    gap: 4px;
  `,
  MenuCoin: styled.div`
    flex: 1;
    img {
      height: 16px;
    }
    strong {
      color: white;
      font-family: Quantico;
    }
  `,
  MenuIcon: styled.div`
    img {
      width: 32px;
      height: 32px;
    }
  `,
  MenuLabel: styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 10px;
    color: #8e8e8e;
    img {
      height: 12px;
    }
  `,
};
